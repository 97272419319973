<template>
  <div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Logout',
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.logout().then(() => {
          this.$router.replace({name: 'Login'}).catch(() => {})
        })
      } else {
        this.$router.replace({name: 'Login'}).catch(() => {})
      }
    })
  },
}
</script>
